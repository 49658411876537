import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/page-header'
import PageSubtitle from '../components/page-subtitle'

const ProjectArticle = ({ project, index }) => {
  const {
    projectName,
    image: {
      file: { url: imageSrc },
    },
    client,
    productApplication: { productApplication },
    productsUsed,
    architect,
    hvacContractor,
    mainContractor,
    mepConsultant,
    location,
    remarks: { remarks },
  } = project

  return (
    <article className="project-ar border border-top-0 border-right-0 border-left-0">
      <div className="row align-items-center justify-content-around">
        <div className={`col-md-6 order-1 ${index % 2 === 0 ? 'order-md-1' : 'order-md-2'}`}>
          <div className="text-center">
            <h3 className="font-weight-bold pt-4">{projectName}</h3>
            <p>{location}</p>
          </div>
          <div className="mt-3 text-justify">
            <p>{remarks}</p>
          </div>
          <div className="row mt-4">
            <div className="col-4 text-grey-6">Client</div>
            <div className="col-1 text-grey-6">:</div>
            <div className="col-7">{client}</div>
          </div>
          <div className="row">
            <div className="col-4 text-grey-6">Main contractor</div>
            <div className="col-1 text-grey-6">:</div>
            <div className="col-7">{mainContractor}</div>
          </div>
          <div className="row">
            <div className="col-4 text-grey-6">Architect</div>
            <div className="col-1 text-grey-6">:</div>
            <div className="col-7">{architect}</div>
          </div>
          <div className="row">
            <div className="col-4 text-grey-6">HVAC contractor</div>
            <div className="col-1 text-grey-6">:</div>
            <div className="col-7">{hvacContractor}</div>
          </div>
          <div className="row">
            <div className="col-4 text-grey-6">MEP consultant</div>
            <div className="col-1 text-grey-6">:</div>
            <div className="col-7">{mepConsultant}</div>
          </div>
          <div className="row">
            <div className="col-4 text-grey-6">Products supplied</div>
            <div className="col-1 text-grey-6">:</div>
            <div className="col-7">
              {productsUsed.map((product) => (
                <Link
                  key={product.name}
                  to={`/products/${product.slug}`}
                  className="badge badge-light background-grey-3 mr-2 font-weight-light">
                  {product.name}
                </Link>
              ))}
            </div>
          </div>
          <div className="background-grey-2 mt-4 p-3 mb-5 rounded">
            <p className="font-size-3 text-grey-6">Products application in the project</p>
            <p className="font-italic font-size-5 mb-0">{productApplication}</p>
          </div>
        </div>
        <div className={`col-md-4 text-right ${index % 2 === 0 ? 'order-md-2' : 'order-md-1'}`}>
          <img
            className="rounded-lg shadow-lg project-img responsive-img"
            alt={`${projectName},${location}`}
            src={`https:${imageSrc}`}
          />
        </div>
      </div>
    </article>
  )
}

export default (props) => {
  const {
    pageTitle,
    subtitle: { subtitle },
    projects,
  } = props.data.allContentfulPageProjects.nodes[0]
  return (
    <Layout pageURL={props.uri}>
      <SEO title={pageTitle} />
      <main>
        <PageHeader pageTitle={pageTitle} />
        <section className="container mt-5 mb-4">
          <PageSubtitle subtitle={subtitle} />
        </section>
        <section className="container mt-5 mb-5">
          {projects.map((project, index) => (
            <ProjectArticle project={project} index={index} key={index} />
          ))}
          <h2 className="text-center pt-5">&amp; many more...</h2>
        </section>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query projectsPageQuery {
    allContentfulPageProjects {
      nodes {
        pageTitle
        subtitle {
          subtitle
        }
        projects {
          projectName
          client
          image {
            file {
              url
            }
          }
          productApplication {
            productApplication
          }
          productsUsed {
            name
            slug
          }
          architect
          hvacContractor
          mainContractor
          mepConsultant
          location
          remarks {
            remarks
          }
        }
      }
    }
  }
`
